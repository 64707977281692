/*
 *
 * Reports reducer
 * TODO:
 * - Clean the code up
 */
import { fromJS, List, Map } from 'immutable'
import { ReportRecord } from 'records'

import {
  COPY_REPORT_SELECTION,
  COPY_REPORT_SELECTION_ERROR,
  COPY_REPORT_SELECTION_SUCCESS,
  CREATE_REPORT_SELECTION,
  CREATE_REPORT_SELECTION_ERROR,
  CREATE_REPORT_SELECTION_SUCCESS,
  CREATE_REPORT_TAG,
  CREATE_REPORT_TAG_SUCCESS,
  DELETE_REPORT_SELECTION,
  DELETE_REPORT_SELECTION_ERROR,
  DELETE_REPORT_SELECTION_SUCCESS,
  DELETE_REPORT_TAG,
  DELETE_REPORT_TAG_ERROR,
  DELETE_REPORT_TAG_SUCCESS,
  EDIT_REPORT_TAG,
  EDIT_REPORT_TAG_SUCCESS,
  GET_REPORT_SELECTION,
  GET_REPORT_SELECTIONS,
  GET_REPORT_SELECTIONS_ERROR,
  GET_REPORT_SELECTIONS_SUCCESS,
  GET_REPORT_SELECTION_ERROR,
  GET_REPORT_SELECTION_SUCCESS,
  GET_REPORT_TAGS,
  GET_REPORT_TAGS_SUCCESS,
  RESET_REPORT_SETTINGS,
  RESET_REPORT_SETTINGS_ERROR,
  RESET_REPORT_SETTINGS_SUCCESS,
  SAVE_REPORT_SETTINGS,
  SAVE_REPORT_SETTINGS_ERROR,
  SAVE_REPORT_SETTINGS_SUCCESS,
  UPDATE_REPORT_SELECTION,
  UPDATE_REPORT_SELECTION_ERROR,
  UPDATE_REPORT_SELECTION_SUCCESS,
} from './constants'
import { SAVE_REPORT_SELECTION_SUCCESS } from '../ReportSelections/constants'

const initialState = fromJS({
  // `reportSelections` are user specific
  reportSelections: Map(),
  // for listing view
  partialReportSelectionsForListing: undefined,
  // `reportSchemes` are mapped by companyCode
  // TODO: Do we really need this? Can't we use selectors to do this?
  reportSchemes: Map(),
  loading: false,
  error: false,
  tagsError: false,
  tagsLoading: false,
  tags: undefined,
})

export const reportSelectionReducer = (state, action) => {
  switch (action.type) {
    case GET_REPORT_SELECTION:
      return state.set(action.reportId, {
        error: false,
        loading: true,
      })

    case GET_REPORT_SELECTION_ERROR:
      return state.set(action.error.reportId, {
        error: action.error,
        loading: false,
      })

    case GET_REPORT_SELECTION_SUCCESS:
    case SAVE_REPORT_SELECTION_SUCCESS:
      return state.set(action.reportId, {
        data: new ReportRecord(action.reportSelection),
        error: false,
        loading: false,
      })

    case RESET_REPORT_SETTINGS: {
      return state.set(action.reportSelectionId, {
        ...state.get(action.reportSelectionId),
        resetReportSettingsLoading: true,
      })
    }

    case RESET_REPORT_SETTINGS_ERROR: {
      return state.set(action.reportSelectionId, {
        ...state.get(action.reportSelectionId),
        error: action.error,
        resetReportSettingsLoading: false,
      })
    }

    case RESET_REPORT_SETTINGS_SUCCESS: {
      return state.set(action.reportSelectionId, {
        ...state.get(action.reportSelectionId),
        data: undefined,
        resetReportSettingsLoading: false,
      })
    }

    case SAVE_REPORT_SETTINGS: {
      return state.set(action.reportSelectionId, {
        ...state.get(action.reportSelectionId),
        saveReportSettingsLoading: true,
      })
    }

    case SAVE_REPORT_SETTINGS_ERROR: {
      return state.set(action.reportSelectionId, {
        ...state.get(action.reportSelectionId),
        error: action.error,
        saveReportSettingsLoading: false,
      })
    }

    case SAVE_REPORT_SETTINGS_SUCCESS: {
      return state.set(action.reportSelectionId, {
        ...state.get(action.reportSelectionId),
        data: new ReportRecord(action.reportSelection),
        saveReportSettingsLoading: false,
      })
    }

    // case SAVE_REPORT_SELECTION_SUCCESS: {
    //   const reportSelectionForState = new ReportRecord(action.reportSelection)
    //   const reportMap = state.get('reportSelections')
    //   const updatedMap = reportMap.set(
    //     reportSelectionForState.id,
    //     reportSelectionForState
    //   )
    //   return state
    //     .set('loading', false)
    //     .set('error', false)
    //     .set('reportSelections', updatedMap)
    // }

    default:
      return state
  }
}

export const reportsReducer = (state = initialState, action) => {
  const newState = state.set(
    'reportSelections',
    reportSelectionReducer(state.get('reportSelections'), action)
  )

  switch (action.type) {
    case COPY_REPORT_SELECTION:
    case CREATE_REPORT_SELECTION:
    case DELETE_REPORT_SELECTION:
    case UPDATE_REPORT_SELECTION: {
      return newState.set('loading', true).set('error', false)
    }

    case COPY_REPORT_SELECTION_ERROR:
    case CREATE_REPORT_SELECTION_ERROR:
    case DELETE_REPORT_SELECTION_ERROR:
    case UPDATE_REPORT_SELECTION_ERROR: {
      return newState.set('loading', false).set('error', action.error)
    }

    case COPY_REPORT_SELECTION_SUCCESS:
    case CREATE_REPORT_SELECTION_SUCCESS:
    case UPDATE_REPORT_SELECTION_SUCCESS: {
      // TODO: It would be better if BE returned all ids as strings
      const reportSelectionId = action.reportSelection.id.toString()
      return newState
        .setIn(['partialReportSelectionsForListing', reportSelectionId], {
          data: new ReportRecord({
            ...action.reportSelection,
            dataSources: [],
            visualisationOverride: null,
          }),
          loading: false,
          error: false,
        })
        .setIn(['reportSelections', reportSelectionId], {
          data: new ReportRecord(action.reportSelection),
          loading: false,
          error: false,
        })
        .set('loading', false)
        .set('error', false)
    }

    case DELETE_REPORT_SELECTION_SUCCESS: {
      // TODO: It would be better if BE returned all ids as strings
      const reportSelectionId = action.reportSelectionId.toString()
      return newState
        .deleteIn(['reportSelections', reportSelectionId])
        .deleteIn(['partialReportSelectionsForListing', reportSelectionId])
        .set('loading', false)
        .set('error', false)
    }

    case GET_REPORT_SELECTIONS:
      return newState.set('loading', true).set('error', false)

    case GET_REPORT_SELECTIONS_SUCCESS: {
      const reportSelections = action.reportSelections.map(
        (reportSelection) => {
          // TODO: It would be better if BE returned all ids as strings
          const reportSelectionId = reportSelection.id.toString()
          return [
            reportSelectionId,
            {
              data: new ReportRecord(reportSelection),
              error: false,
              loading: false,
            },
          ]
        }
      )

      return newState
        .set('loading', false)
        .set('partialReportSelectionsForListing', new Map(reportSelections))
    }

    case GET_REPORT_SELECTIONS_ERROR:
      return newState.set('loading', false).set('error', action.error)

    case EDIT_REPORT_TAG:
    case DELETE_REPORT_TAG:
    case CREATE_REPORT_TAG:
    case GET_REPORT_TAGS:
      return newState.set('tagsLoading', true)

    case DELETE_REPORT_TAG_ERROR:
      return newState.set('tagsLoading', false).set('tagsError', action.error)
    case GET_REPORT_TAGS_SUCCESS:
      return newState
        .set('tags', new List(action.tags))
        .set('tagsLoading', false)

    case CREATE_REPORT_TAG_SUCCESS:
      return newState
        .set('tags', newState.get('tags').push(action.tag))
        .set('tagsLoading', false)

    case EDIT_REPORT_TAG_SUCCESS:
      const newStateTag = newState
        .get('tags')
        .filter((tag) => tag.id !== action.tag.id)
      return newState
        .set('tags', newStateTag.push(action.tag))
        .set('tagsLoading', false)

    case DELETE_REPORT_TAG_SUCCESS:
      const filteredTagState = newState
        .get('tags')
        .filter((tag) => tag.id !== action.tagId)
      return newState.set('tags', filteredTagState).set('tagsLoading', false)

    default:
      return newState
  }
}

export default reportsReducer
