import { Map, List } from 'immutable'
import moment from 'moment'
import { lowerFirst, pickBy, identity, set } from 'lodash'

import {
  ACCOUNTING_PERIOD_TYPE_CURRENT,
  COMPANY_PRES_TYPE_COMPANY_PER_COLUMN,
  DATATYPE_ACTUAL,
  DATATYPE_FORMULA,
  DATEFORMAT_VALUES,
  DECIMALS_TWO,
  DIMENSION_PRES_TYPE_DIMENSIONS_TOTAL,
  DIMENSION_PRES_TYPE_DIMENSION_MAPPING_ARRAY,
  DYNAMIC_END_DATE_ACCOUNTING_PERIOD_END_DATE,
  FORMULA_OPERATOR_PLUS,
  FORMULA_OPERATOR_MINUS,
  FORMULA_OPERATOR_DIVIDE,
  FORMULA_OPERATOR_TIMES,
  FORMULA_OPERATOR_DIFF_PERCENTAGE,
  FORMULA_OPERATOR_COMPLEX,
  PRECISION_ONE,
  THOUSAND_SEPARATOR_MAPPING_ARRAY,
  SEPARATOR_SPACE,
  SEPARATOR_COMMA,
  PERIOD_PRES_TYPE_MONTH_PER_COLUMN,
  REPORT_PERIOD_TYPE_ACCOUNTING_PERIOD,
  DECIMAL_COUNT_MAPPING_ARRAY,
  DECIMAL_SEPARATOR_MAPPING_ARRAY,
  PRECISION_MAPPING_ARRAY,
  SHOW_IN_COLUMNS_OR_ROWS_MAPPINGS_ARRAY,
  COMPANY_PRES_TYPE_MAPPING_ARRAY,
  ACCOUNTING_PERIOD_TYPE_MAPPING_ARRAY,
  DYNAMIC_START_DATE_MAPPING_ARRAY,
  DYNAMIC_START_DATE_ACCOUNTING_PERIOD_START_DATE,
  DYNAMIC_END_DATE_MAPPING_ARRAY,
  DATATYPE_MAPPING_ARRAY,
  PERIOD_PRES_TYPE_MAPPING_ARRAY,
  REPORT_PERIOD_TYPE_MAPPING_ARRAY,
  TOP_AND_OTHERS_MAPPING_ARRAY,
  TOP_AND_OTHERS_DEFAULT,
  REPORT_SELECTION_TYPE_SYSTEM,
  REPORT_SELECTION_TYPE_CUSTOMER,
  REPORT_SELECTION_TYPE_USER,
} from './constants'
import ReportSelectionFEToBETransformer from './Transformers/reportSelectionFEToBETransformer'
import ReportSelectionToReportConfigTransformer from './Transformers/reportSelectionToFlexmonsterConfig'

import messages from 'containers/ReportSelections/messages'
import { getFirstFreeNameWithoutCopy } from 'containers/ReportSelectionsBeta/functions'

export const fetchMissingCompanySpecificData = ({
  items,
  companies,
  fetchFn,
  customers,
}) => {
  const weHaveItemsForAllCompanies =
    !companies ||
    (items && companies.every((company) => items.has(company.code)))

  if (!weHaveItemsForAllCompanies) {
    const missingCompanies = companies
      .map((company) => company.code)
      .filter((code) => !items.has(code))

    missingCompanies.forEach((code) => {
      fetchFn({
        companyCode: code,
        customerCode: customers.find((c) =>
          c.companies.find((cmp) => cmp.code === code)
        ).code,
      })
    })
  }
  return weHaveItemsForAllCompanies
}

const createBlankReport = ({ companyCode, reportSchemes, companies }) => {
  const defaultCompany = companies.find((c) => c.code === companyCode)
  const defaultCompanies = defaultCompany ? [defaultCompany.toJS()] : []
  const defaultReportScheme = getDefaultReportScheme({
    reportSchemes,
    companyCode,
  })

  return {
    defaultCompanies,
    reportScheme: defaultReportScheme,
    reportSchemeId: defaultReportScheme && defaultReportScheme.id,
    showPercentageColumn: false,
    percentageTarget: '',
    dateFormat: DATEFORMAT_VALUES.YEAR_AND_MONTH,
    showTotalColumn: false,
    showSubTotals: true,
    useOverlappingMonths: false,
    decimals: DECIMALS_TWO,
    precisionType: PRECISION_ONE,
    thousandSeparatorType: SEPARATOR_SPACE,
    decimalSeparatorType: SEPARATOR_COMMA,
    showInColumns: SHOW_IN_COLUMNS_OR_ROWS_MAPPINGS_ARRAY,
    showInRows: [],
    reportDataSources: [],
    currencyCode: '',
  }
}

export const createBlankDatasource = ({
  reportDataSources = [{ id: 0, order: -1 }],
  companies = [],
  generateName,
}) => {
  // Generating ids in the frontend... not good...
  const newId =
    reportDataSources
      .map((d) => parseInt(d.id, 10))
      .reduce((a, b) => Math.max(a, b), 0) + 1
  const order =
    reportDataSources
      .map((d) => parseInt(d.order, 10))
      .reduce((a, b) => Math.max(a, b), -1) + 1

  return {
    id: newId,
    order,
    dataType: DATATYPE_ACTUAL,
    name: generateName
      ? getFirstFreeNameWithoutCopy(
          reportDataSources,
          'Actual - Current Period'
        )
      : `Datasource ${newId}`,
    companies,
    dimensions: Map(companies.map((c) => [c.id, []])), // dimensions are mapped by companyId
    dimensionValues: Map(companies.map((c) => [c.id, []])), // dimensionValues are mapped by companyId
    budgets: Map(companies.map((c) => [c.id, undefined])), // ...and budgets
    reportingGroups: Map(companies.map((c) => [c.id, []])), // ...and reporting groups, too
    // isLinked: false,
    companyPresentationType: COMPANY_PRES_TYPE_COMPANY_PER_COLUMN,
    dimensionPresentationType: DIMENSION_PRES_TYPE_DIMENSIONS_TOTAL, //DIMENSION_PRES_TYPE_DIMENSION_PER_COLUMN,
    showTopRowsCount: TOP_AND_OTHERS_DEFAULT, // default = no top selection = 0
    periodType: REPORT_PERIOD_TYPE_ACCOUNTING_PERIOD,
    start: new Date(),
    end: new Date(),
    accountingPeriodType: ACCOUNTING_PERIOD_TYPE_CURRENT,
    periodPresentationType: PERIOD_PRES_TYPE_MONTH_PER_COLUMN,
    dynamicStartDateType: DYNAMIC_START_DATE_ACCOUNTING_PERIOD_START_DATE,
    dynamicEndDateType: DYNAMIC_END_DATE_ACCOUNTING_PERIOD_END_DATE,
    comparisonSecondDataSource: '',
    comparisonOperator: FORMULA_OPERATOR_MINUS,
    comparisonFirstDataSource: '',
    formula: '',
    isNew: true, // Flag tells that datasource is just created. It is used in onSubmit to remove temporary id. Dont save the flag!
    setOpen: true, // Flag tells that datasource is just created and should be opened. Dont save the flag!
  }
}

export const isFormulaDataSource = (ds) =>
  ds.dataType === 2 || ds.dataType === 'FormulaData'

const transformBEDataSourceCompaniesToFECompanies = ({
  companies,
  reportDataSource,
}) =>
  reportDataSource.companies.map((tmpCompany) => {
    if (!tmpCompany) return undefined
    const id = tmpCompany.companyId
    const companyRecord = companies.find(
      (comp) => parseInt(comp.id, 10) === parseInt(id, 10)
    )
    if (!companyRecord) return undefined
    const companyObject = companyRecord.toJS()
    companyObject.budgetId = tmpCompany.budgetId
    companyObject.useExclusiveDimensionQuery =
      tmpCompany.useExclusiveDimensionQuery
    companyObject.useReportingCodesInsteadAccountNumbers =
      tmpCompany.useReportingCodesInsteadAccountNumbers
    companyObject.useGroupDates = tmpCompany.useGroupDates
    companyObject.exchangeRate = tmpCompany.exchangeRate
    return companyObject
  })
export const mapAllReportSelectionDataSourcesCompaniesToCompanyArray = ({
  companies,
  reportDataSources,
}) => {
  if (!reportDataSources || !companies) return []
  const allCompanies = reportDataSources.flatMap((reportDataSource) => {
    const companyArray = transformBEDataSourceCompaniesToFECompanies({
      companies,
      reportDataSource,
    })
    return companyArray
  })
  // trim and remove dublicates
  const companyArray = allCompanies.filter(
    (companyA, index, thisArray) =>
      companyA && // this is trim
      index ===
        thisArray.findIndex(
          (
            companyB // this is distinct
          ) => companyA.id === companyB.id
        )
  )
  const returnValue = companyArray || []
  return returnValue
}
const createEditableReport = ({
  companies,
  reportSchemes,
  companyCode,
  reportSelectionToEdit,
}) => {
  const output = createBlankReport({ companies, reportSchemes, companyCode })
  if (!reportSelectionToEdit) {
    return output
  }
  const reportSelection = reportSelectionToEdit.toJS()
  output.defaultCompanies = mapAllReportSelectionDataSourcesCompaniesToCompanyArray(
    { reportDataSources: reportSelection.reportDataSources, companies }
  )
  let reportSchemeCandidate
  reportSchemes.forEach((list) => {
    reportSchemeCandidate = list.find(
      (scheme) =>
        parseInt(scheme.id, 10) ===
        parseInt(reportSelectionToEdit.reportSchemeId, 10)
    )
    if (reportSchemeCandidate) return false // returning false for foreach breaks the loop.
    return 1
  })
  output.reportScheme = reportSchemeCandidate
  output.reportSchemeId = reportSelectionToEdit.reportSchemeId
  const reportDataSourcesInForm = reportSelectionToEdit.reportDataSources.map(
    (ds) =>
      transformBEDataSourceToForm({
        companies,
        ds,
      })
  )
  output.reportDataSources = reportDataSourcesInForm
  output.name = reportSelectionToEdit.name
  output.showTotalColumn = reportSelectionToEdit.showTotalColumn
  output.showSubTotals = reportSelectionToEdit.showSubTotals
  output.showPercentageColumn = reportSelectionToEdit.showPercentageColumn
  output.useOverlappingMonths = reportSelectionToEdit.useOverlappingMonths
  output.currencyCode = reportSelectionToEdit.currencyCode
  output.dateFormat = reportSelectionToEdit.dateFormat || 'yyyy/MM'
  output.percentageTarget = reportSelectionToEdit.percentageTarget
  output.percentageTargetId = reportSelectionToEdit.percentageTargetId
  output.id = reportSelectionToEdit.id
  output.decimalSeparatorType =
    DECIMAL_SEPARATOR_MAPPING_ARRAY[reportSelectionToEdit.decimalSeparatorType]
  output.decimals = DECIMAL_COUNT_MAPPING_ARRAY[reportSelectionToEdit.decimals]
  output.thousandSeparatorType =
    THOUSAND_SEPARATOR_MAPPING_ARRAY[
      reportSelectionToEdit.thousandSeparatorType
    ]
  output.precisionType =
    PRECISION_MAPPING_ARRAY[reportSelectionToEdit.precisionType]
  output.showInColumns = mapRowsOrColumns(reportSelectionToEdit.showInColumns)
  output.showInRows = mapRowsOrColumns(reportSelectionToEdit.showInRows)
  output.reportSelectionUsers = reportSelectionToEdit.reportSelectionUsers
  output.reportSelectionRoles = reportSelectionToEdit.reportSelectionRoles
  output.type = reportSelectionToEdit.type
  output.customerId = reportSelectionToEdit.customerId
  output.reportSelectionTagIds = reportSelectionToEdit.reportSelectionTagIds
  output.includeLastSchemeLevelAsDataRow =
    reportSelectionToEdit.includeLastSchemeLevelAsDataRow
  return output
}
const transformBEDataSourceToForm = ({ companies, ds }) => {
  const dsInForm = {}
  dsInForm.id = ds.id

  dsInForm.budgets = Map(
    ds.companies.map((dsCompany) => [
      String(dsCompany.companyId),
      dsCompany.budgetId ? String(dsCompany.budgetId) : undefined, //We don't want string valued "null"
    ])
  )
  dsInForm.dimensionValues = Map(
    ds.companies.map((dsCompany) => [
      String(dsCompany.companyId),
      dsCompany.dimensionValueIds,
    ])
  )
  dsInForm.dimensions = Map(
    ds.companies.map((dsCompany) => [
      String(dsCompany.companyId),
      dsCompany.dimensionIds,
    ])
  )
  dsInForm.reportingGroups = Map(
    ds.companies.map((dsCompany) => [
      String(dsCompany.companyId),
      dsCompany.reportingGroupIds.map(String),
    ])
  )

  dsInForm.companies = transformBEDataSourceCompaniesToFECompanies({
    companies,
    reportDataSource: ds,
  })
  dsInForm.name = ds.name
  dsInForm.dataType = DATATYPE_MAPPING_ARRAY[ds.dataType]
  if (dsInForm.dataType === DATATYPE_FORMULA) {
    // split field values from formula string
    const values = !ds.formulaSelections
      ? FormulaParser.parseSelectionValuesFromString(ds.formula)
      : {
          comparisonFirstDataSource:
            ds.formulaSelections.comparisonFirstDataSource,
          comparisonOperator: ds.formulaSelections.comparisonOperator,
          comparisonSecondDataSource:
            ds.formulaSelections.comparisonSecondDataSource,
        }
    dsInForm.comparisonFirstDataSource = values.comparisonFirstDataSource
    dsInForm.comparisonOperator = values.comparisonOperator
    dsInForm.comparisonSecondDataSource = values.comparisonSecondDataSource
    dsInForm.formula = FormulaParser.transformSelectionsToFormula(values)
  } else {
    dsInForm.comparisonFirstDataSource = ''
    dsInForm.comparisonOperator = FORMULA_OPERATOR_MINUS
    dsInForm.comparisonSecondDataSource = ''
    dsInForm.formula = ''
  }
  dsInForm.companyPresentationType =
    COMPANY_PRES_TYPE_MAPPING_ARRAY[ds.companyPresentationType]
  dsInForm.dimensionPresentationType =
    DIMENSION_PRES_TYPE_DIMENSION_MAPPING_ARRAY[ds.dimensionPresentationType]
  dsInForm.showTopRowsCount = TOP_AND_OTHERS_MAPPING_ARRAY[ds.showTopRowsCount]
  dsInForm.periodType = REPORT_PERIOD_TYPE_MAPPING_ARRAY[ds.periodType]
  dsInForm.accountingPeriodType =
    ACCOUNTING_PERIOD_TYPE_MAPPING_ARRAY[ds.accountingPeriodType]
  dsInForm.dynamicStartDateType =
    DYNAMIC_START_DATE_MAPPING_ARRAY[ds.dynamicStartDateType]
  dsInForm.monthsFromDynamicStartDate = ds.monthsFromDynamicStartDate
  dsInForm.includeDynamicStartMonth = ds.includeDynamicStartMonth
  dsInForm.dynamicEndDateType =
    DYNAMIC_END_DATE_MAPPING_ARRAY[ds.dynamicEndDateType]
  dsInForm.periodPresentationType =
    PERIOD_PRES_TYPE_MAPPING_ARRAY[ds.periodPresentationType]
  dsInForm.end = moment(ds.end).toDate()
  dsInForm.start = moment(ds.start).toDate()
  dsInForm.order = ds.order
  return dsInForm
}

export const createReportFormDataObject = ({
  companies = new Map(),
  companyCode = undefined,
  reportSchemes,
  reportSelectionToEdit,
}) => {
  return createEditableReport({
    companies,
    reportSchemes,
    companyCode,
    reportSelectionToEdit,
  })
}
const getDefaultReportScheme = ({ reportSchemes, companyCode }) => {
  if (reportSchemes.has(companyCode)) {
    const firstReportScheme = reportSchemes.get(companyCode).get(0)
    return firstReportScheme ? firstReportScheme.toJS() : undefined
  }

  return undefined
}

export const mapRowsOrColumns = (incomingArray, transformToLower = false) => {
  const returnValue = incomingArray.map((number) => {
    if (!Number.isInteger(number)) {
      return transformToLower ? number.toLowerCase() : number
    }
    if (number < 1 || number > SHOW_IN_COLUMNS_OR_ROWS_MAPPINGS_ARRAY.length) {
      return undefined
    }
    const retVal = SHOW_IN_COLUMNS_OR_ROWS_MAPPINGS_ARRAY[number - 1]
    if (transformToLower) return retVal.toLowerCase()
    return retVal
  })
  return returnValue
}

export const transformReportSelectionToFlexMonsterReportConfig = ({
  dimensionNames,
  reportSelection,
  reportData,
  beta,
}) => {
  if (!reportData) return {}
  const transformer = new ReportSelectionToReportConfigTransformer(
    reportData,
    dimensionNames
  )
  const output = transformer.transform(reportSelection, beta)
  return applyVisualizationOverride(
    output,
    reportSelection.visualizationOverride
  )
}

export const applyVisualizationOverride = (
  flexmonsterReportConfig,
  visualizationOverride
) =>
  removeUndefinedProperties({
    conditions: visualizationOverride && visualizationOverride.conditions,
    dataSource: flexmonsterReportConfig.dataSource,
    formats:
      (visualizationOverride && visualizationOverride.formats) ||
      flexmonsterReportConfig.formats,
    options: resolveFlexmonsterOptions(
      visualizationOverride && visualizationOverride.options,
      flexmonsterReportConfig.options
    ),
    slice:
      (visualizationOverride && visualizationOverride.slice) ||
      flexmonsterReportConfig.slice,
    customFields:
      (visualizationOverride && visualizationOverride.customFields) ||
      flexmonsterReportConfig.customFields,
    defaultSelections: {
      conditions: flexmonsterReportConfig.conditions,
      formats: flexmonsterReportConfig.formats,
      options: flexmonsterReportConfig.options,
      slice: flexmonsterReportConfig.slice,
      customFields: flexmonsterReportConfig.customFields,
    },
  })

const resolveFlexmonsterOptions = (
  visualizationOverrideOptions,
  flexmonsterReportConfigOptions
) => {
  const resolved =
    visualizationOverrideOptions || flexmonsterReportConfigOptions

  // title is always from the flexmonster config
  set(resolved, 'grid.title', flexmonsterReportConfigOptions.grid.title)
  return resolved
}

const removeUndefinedProperties = (obj) => pickBy(obj, identity)

export const transformFEReportSelectionToBEFormat = ({
  reportSelection,
  userId,
}) => {
  const transformer = new ReportSelectionFEToBETransformer()
  return transformer.transform({ feReportSelection: reportSelection, userId })
}

export const getDimensionNamesFromReportData = (reportData) => {
  if (!reportData) return []

  //first object is metadata about raport
  const metaDataObject = reportData[0]
  return Object.keys(metaDataObject).filter(
    (metaDataKey) =>
      metaDataObject[metaDataKey] &&
      metaDataObject[metaDataKey].type &&
      metaDataObject[metaDataKey].type === 'dimension'
  )
}

export class FormulaParser {
  static mapOperatorToStringConstant = (beSeparator) => {
    switch (beSeparator) {
      case '+':
        return FORMULA_OPERATOR_PLUS
      case '-':
        return FORMULA_OPERATOR_MINUS
      case '/':
        return FORMULA_OPERATOR_DIVIDE
      case '*':
        return FORMULA_OPERATOR_TIMES
      default:
        return FORMULA_OPERATOR_COMPLEX
    }
  }
  static mapStringConstantToOperator = (comparisonOperatorSelection) => {
    switch (comparisonOperatorSelection) {
      case FORMULA_OPERATOR_PLUS:
        return '+'
      case FORMULA_OPERATOR_MINUS:
        return '-'
      case FORMULA_OPERATOR_DIVIDE:
        return '/'
      case FORMULA_OPERATOR_TIMES:
        return '*'
      case FORMULA_OPERATOR_COMPLEX:
        return '?'
      default:
        return comparisonOperatorSelection // cannot convert. give it back
    }
  }

  static parseSelectionValuesFromString = (formulaStr) => {
    if (!formulaStr) return null
    const firstPartEnd = formulaStr.indexOf('") ')
    const firstPart = formulaStr.substring('sum("'.length, firstPartEnd).trim()
    const separatorStart = firstPartEnd + '") '.length
    const separator = formulaStr
      .substring(separatorStart, separatorStart + 1)
      .trim()
    const secondPart = formulaStr
      .substring(firstPartEnd + '") ? sum("'.length, formulaStr.length - 2)
      .trim()
    const returnValue = {
      comparisonFirstDataSource: firstPart,
      comparisonOperator: FormulaParser.mapOperatorToStringConstant(separator),
      comparisonSecondDataSource: secondPart,
    }
    return returnValue
  }
  static transformSelectionsToFormula = ({
    comparisonFirstDataSource,
    comparisonOperator,
    comparisonSecondDataSource,
  }) => {
    // const first = lowerFirst(comparisonFirstDataSource);
    const first = lowerFirst(comparisonFirstDataSource)
    const second = FormulaParser.mapStringConstantToOperator(comparisonOperator)
    // const third = lowerFirst(comparisonSecondDataSource);
    const third = lowerFirst(comparisonSecondDataSource)
    if (second === FORMULA_OPERATOR_DIFF_PERCENTAGE) {
      // return `(sum("${third}") - sum("${first}")) / sum("${first}")`;
      return `(sum("${first}") - sum("${third}")) / abs(sum("${third}"))`
    }
    return `sum("${first}") ${second} sum("${third}")`
  }
}

export const mapSystemScopeToReportSchemeName = ({
  definedInScope,
  name,
  ...reportScheme
}) => ({
  name: name + (definedInScope === 'System' ? ' (System)' : ''),
  ...reportScheme,
})

export const mapSystemAndCustomerScopeToReportSchemeName = ({
  definedInScope,
  name,
  ...reportScheme
}) => ({
  name:
    name +
    (definedInScope === 'System'
      ? ' (System)'
      : definedInScope === 'Customer'
      ? ' (Customer)'
      : ''),
  ...reportScheme,
})

export const currencyCodeChoiceArray = (formatMessage) => [
  { text: formatMessage(messages.currencyCodeNoConversion), value: '' },
  { text: 'EUR', value: 'EUR' },
  { text: 'GBP', value: 'GBP' },
  { text: 'NOK', value: 'NOK' },
  { text: 'SEK', value: 'SEK' },
  { text: 'DKK', value: 'DKK' },
  { text: 'USD', value: 'USD' },
  { text: 'AUD', value: 'AUD' },
  { text: 'BTC', value: 'BTC' },
  { text: 'BYN', value: 'BYN' },
  { text: 'CHF', value: 'CHF' },
  { text: 'CNY', value: 'CNY' },
  { text: 'CZK', value: 'CZK' },
  { text: 'HKD', value: 'HKD' },
  { text: 'HRK', value: 'HRK' },
  { text: 'INR', value: 'INR' },
  { text: 'JPY', value: 'JPY' },
  { text: 'KPW', value: 'KPW' },
  { text: 'NZD', value: 'NZD' },
  { text: 'PLN', value: 'PLN' },
  { text: 'RUB', value: 'RUB' },
  { text: 'SGD', value: 'SGD' },
  { text: 'THB', value: 'THB' },
  { text: 'TRY', value: 'TRY' },
]

export const getDuplicateDataSourceNames = (dataSourceArray) => {
  const reportDataSourceNames = dataSourceArray.map((ds) => ds.name)
  return reportDataSourceNames.filter(
    (v, i, a) => a.indexOf(v) !== a.lastIndexOf(v)
  )
}

export const getFormulaSelectionsFromDatasource = (datasource) => ({
  comparisonFirstDataSource: datasource.comparisonFirstDataSource,
  comparisonOperator: datasource.comparisonOperator,
  comparisonSecondDataSource: datasource.comparisonSecondDataSource,
})

export const ripIndex = (fieldName) => {
  if (!fieldName) return -1
  const bracketStart = fieldName.indexOf('[')
  const bracketEnd = fieldName.indexOf(']')
  if (bracketEnd < 0 || bracketStart < 0) return -1
  const index = fieldName.slice(bracketStart + 1, bracketEnd)
  return index
}

export const getUsedReportNames = ({ reports, reportSelectionToEdit }) =>
  reports.map((report) => {
    if (reportSelectionToEdit && reportSelectionToEdit.id === report.id)
      return ''
    return report.name
  })

export const isSystemReportView = (location) =>
  location &&
  location.pathname &&
  location.pathname.toLowerCase().includes('systemreportselections')

export const isCustomerReportView = (location) =>
  location &&
  location.pathname &&
  location.pathname.toLowerCase().includes('customerreports')

export const getReportTypeFromLocation = (location) => {
  if (isSystemReportView(location)) return REPORT_SELECTION_TYPE_SYSTEM
  if (isCustomerReportView(location)) return REPORT_SELECTION_TYPE_CUSTOMER
  return REPORT_SELECTION_TYPE_USER
}

const getDistinctById = (schemes) =>
  schemes
    .groupBy((reportScheme) => reportScheme.id)
    .map((reportScheme) => reportScheme.first())
    .toList()

export const getSelectableReportSchemes = (
  defaultCompanies,
  customers,
  reportSchemes,
  reportType,
  customerCode
) => {
  if (reportType === REPORT_SELECTION_TYPE_SYSTEM) {
    return getDistinctById(reportSchemes.get('System') || List())
  } else if (reportType === REPORT_SELECTION_TYPE_CUSTOMER) {
    if (reportSchemes.has(customerCode)) {
      const customerAndSystemSchemes = reportSchemes
        .get(customerCode)
        .concat(reportSchemes.get('System') || List())
      return getDistinctById(customerAndSystemSchemes)
    }
    const keyForCompanyUnderCustomer = reportSchemes
      .keySeq()
      .find((key) => key.startsWith(customerCode))
    const schemesForCompanyUnderCustomer =
      reportSchemes.get(keyForCompanyUnderCustomer) || List()
    return getDistinctById(
      schemesForCompanyUnderCustomer.filter(
        (scheme) =>
          scheme?.definedInScope === 'Customer' ||
          scheme?.definedInScope === 'System'
      )
    )
  }
  const schemesForDefaultCompanies = List(defaultCompanies).flatMap(
    (company) => reportSchemes.get(company.code) || List()
  )

  const schemesForCustomersOfDefaultCompanies = List(defaultCompanies).flatMap(
    (company) =>
      reportSchemes.get(
        customers.find((c) =>
          c.companies.find((cmp) => cmp.code === company.code)
        ).code
      ) || List()
  )

  const reportSchemesFromSelectedCompanies = schemesForDefaultCompanies
    .concat(schemesForCustomersOfDefaultCompanies)
    .concat(reportSchemes.get('System') || List())

  const distinctReportSchemes = getDistinctById(
    reportSchemesFromSelectedCompanies
  )
  const selectableReportSchemes = distinctReportSchemes.filter(
    (reportScheme) => {
      // empty companies means this is a customer level scheme
      // 1. check that customer is correct
      // a flag is used for better readability
      let customerIsValid = false
      if (!reportScheme.customers || reportScheme.customers.length < 1) {
        customerIsValid = true //this is a system level scheme
      } else {
        const reportSchemesCustomerIdArray = reportScheme.customers.map(
          (customer) => customer.customerId
        )
        const notSupportedCustomers = defaultCompanies.filter(
          (company) =>
            !reportSchemesCustomerIdArray.includes(
              getCustomerIdForCompany(company, customers)
            )
        )
        if (notSupportedCustomers.length) {
          customerIsValid = false // This scheme is not valid for all selected companies
        } else {
          customerIsValid = true
        }
      }
      if (!customerIsValid) return false
      // 2. check that company is correct
      if (!reportScheme.companies || reportScheme.companies.length < 1) {
        return true
      }
      const reportSchemesCompanyIdArray = reportScheme.companies.map(
        (company) => company.companyId
      )
      //ReportScheme is valid if all ids from defaultCompanies exist in reportSchemesCompanyIdArray.
      //This can achieved by filtering reportSchemesCompanyIdArray stuff away from defaultCompanies.
      //ReportScheme is not valid if even one company is left:
      const notSupportedCompanies = defaultCompanies.filter(
        (company) =>
          !reportSchemesCompanyIdArray.includes(parseInt(company.id, 10))
      )
      if (notSupportedCompanies.length) return false
      return true
    }
  )
  return selectableReportSchemes
}

export const getfilteredReportCompaniesByDefaultCompanies = ({
  form,
  companies = [],
}) => {
  const companyIds = companies.map((cmp) => cmp.id)
  const filteredDatasources = form.reportDataSources.map((ds) => ({
    ...ds,
    companies: ds.companies.filter((comp) => companyIds.includes(comp.id)),
  }))
  return filteredDatasources
}

export const getCustomerIdForCompany = (company, customers) => {
  if (!company) return -1
  const companyId = company.id
  const companysCustomer = customers.find((tmpCustomer) =>
    tmpCustomer.companies.find(
      (tmpCompany) => String(tmpCompany.id) === String(companyId)
    )
  )
  if (!companysCustomer) return -1
  const returnValue = parseInt(companysCustomer.id, 10)

  return returnValue
}

//Generic component to map id -> name and hierarchy for company and customer fields
export const NameIdComponent = (collection) => ({ item }) =>
  getNameById(item, collection)

export const getNameById = (id, collection) => {
  if (!id || !collection) return null
  var entity = collection.find((c) => parseInt(c.id, 10) === parseInt(id, 10))
  if (!entity || !entity.name) return null
  return entity.name
}

export const filterTagsByCompanies = (companies, customers) => (tag) => {
  if (!companies || companies.length === 0) return false
  const customerLevelTag = !tag.companyId
  const userLevelTag = !tag.customerId && !tag.companyId
  if (userLevelTag) return true
  if (customerLevelTag) {
    const hasMatchingCustomer = companies.every(
      (c) => getCustomerIdForCompany(c, customers) === tag.customerId
    )
    return hasMatchingCustomer
  } else {
    const hasMatchingCompany = companies.every(
      (c) => parseInt(c.id, 10) === tag.companyId
    )
    return hasMatchingCompany
  }
}

export const defaultDataForPreview = [
  {
    amount: {
      type: 'number',
    },
    company: {
      type: 'string',
    },
    dataSourceOrder: {
      type: 'number',
      folder: 'Data Property',
    },
    date: {
      type: 'string',
    },
    accountingPeriod: {
      type: 'string',
      folder: 'Data Property',
    },
    isBudgetData: {
      type: 'string',
      folder: 'Data Property',
    },
    isFormulaRowData: {
      type: 'string',
      folder: 'Data Property',
    },
    isCumulativeData: {
      type: 'string',
      folder: 'Data Property',
    },
    sourceCompany: {
      type: 'string',
      folder: 'Data Property',
    },
    recordId: {
      type: 'id',
    },
    realBalanceIds: {
      type: 'string',
      folder: 'Data Property',
    },
    operativeItemIds: {
      type: 'string',
      folder: 'Data Property',
    },
    year: {
      type: 'string',
      folder: 'Dates',
    },
    month: {
      type: 'string',
      folder: 'Dates',
      order: [
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
      ],
    },
  },
]
