/*
 *
 * Reports constants
 * TODO: Some success/error states are missing
 */
export const COPY_REPORT_SELECTION = 'app/Reports/COPY_REPORT_SELECTION'
export const COPY_REPORT_SELECTION_ERROR =
  'app/Reports/COPY_REPORT_SELECTION_ERROR'
export const COPY_REPORT_SELECTION_SUCCESS =
  'app/Reports/COPY_REPORT_SELECTION_SUCCESS'

export const CREATE_REPORT_SELECTION = 'app/Reports/CREATE_REPORT_SELECTION'
export const CREATE_REPORT_SELECTION_ERROR =
  'app/Reports/CREATE_REPORT_SELECTION_ERROR'
export const CREATE_REPORT_SELECTION_SUCCESS =
  'app/Reports/CREATE_REPORT_SELECTION_SUCCESS'

export const CREATE_REPORT_TAG = 'app/Reports/CREATE_REPORT_TAG'
export const CREATE_REPORT_TAG_SUCCESS = 'app/Reports/CREATE_REPORT_TAG_SUCCESS'

export const DELETE_REPORT_SELECTION = 'app/Reports/DELETE_REPORT_SELECTION'
export const DELETE_REPORT_SELECTION_ERROR =
  'app/Reports/DELETE_REPORT_SELECTION_ERROR'

export const DELETE_REPORT_SELECTION_SUCCESS =
  'app/Reports/DELETE_REPORT_SELECTION_SUCCESS'

export const DELETE_REPORT_TAG = 'app/Reports/DELETE_REPORT_TAG'
export const DELETE_REPORT_TAG_SUCCESS = 'app/Reports/DELETE_REPORT_TAG_SUCCESS'
export const DELETE_REPORT_TAG_ERROR = 'app/Reports/DELETE_REPORT_TAG_ERROR'

export const EDIT_REPORT_TAG = 'app/Reports/EDIT_REPORT_TAG'
export const EDIT_REPORT_TAG_SUCCESS = 'app/Reports/EDIT_REPORT_TAG_SUCCESS'

export const GET_REPORT_SELECTION = 'app/Reports/GET_REPORT_SELECTION'
export const GET_REPORT_SELECTION_ERROR =
  'app/Reports/GET_REPORT_SELECTION_ERROR'
export const GET_REPORT_SELECTION_SUCCESS =
  'app/Reports/GET_REPORT_SELECTION_SUCCESS'

export const GET_REPORT_SELECTIONS = 'app/Reports/GET_REPORT_SELECTIONS'
export const GET_REPORT_SELECTIONS_ERROR =
  'app/Reports/GET_REPORT_SELECTIONS_ERROR'
export const GET_REPORT_SELECTIONS_SUCCESS =
  'app/Reports/GET_REPORT_SELECTIONS_SUCCESS'

export const GET_REPORT_TAGS = 'app/Reports/GET_REPORT_TAGS'
export const GET_REPORT_TAGS_SUCCESS = 'app/Reports/GET_REPORT_TAGS_SUCCESS'

export const RESET_REPORT_SETTINGS = 'app/Reports/RESET_REPORT_SETTINGS'
export const RESET_REPORT_SETTINGS_ERROR =
  'app/Reports/RESET_REPORT_SETTINGS_ERROR'
export const RESET_REPORT_SETTINGS_SUCCESS =
  'app/Reports/RESET_REPORT_SETTINGS_SUCCESS'

export const SAVE_REPORT_SETTINGS = 'app/Reports/SAVE_REPORT_SETTINGS'
export const SAVE_REPORT_SETTINGS_SUCCESS =
  'app/Reports/SAVE_REPORT_SETTINGS_SUCCESS'
export const SAVE_REPORT_SETTINGS_ERROR =
  'app/Reports/SAVE_REPORT_SETTINGS_ERROR'

export const UPDATE_REPORT_SELECTION = 'app/Reports/UPDATE_REPORT_SELECTION'
export const UPDATE_REPORT_SELECTION_ERROR =
  'app/Reports/UPDATE_REPORT_SELECTION_ERROR'
export const UPDATE_REPORT_SELECTION_SUCCESS =
  'app/Reports/UPDATE_REPORT_SELECTION_SUCCESS'
