/*
 *
 * Users constants
 *
 */

export const CREATE_USER = 'app/Users/CREATE_USER'
export const CREATE_USER_SUCCESS = 'app/Users/CREATE_USER_SUCCESS'
export const CREATE_USER_ERROR = 'app/Users/CREATE_USER_ERROR'

export const GET_USERS = 'app/Users/GET_USERS'
export const GET_USERS_SUCCESS = 'app/Users/GET_USERS_SUCCESS'
export const GET_USERS_ERROR = 'app/Users/GET_USERS_ERROR'

export const GET_ALL_USERS = 'app/Users/GET_ALL_USERS'
export const GET_ALL_USERS_SUCCESS = 'app/Users/GET_ALL_USERS_SUCCESS'
export const GET_ALL_USERS_ERROR = 'app/Users/GET_ALL_USERS_ERROR'

export const DELETE_USER = 'app/Users/DELETE_USER'
export const DELETE_USER_SUCCESS = 'app/Users/DELETE_USER_SUCCESS'
export const DELETE_USER_ERROR = 'app/Users/DELETE_USER_ERROR'

export const DELETE_AAD_USER = 'app/Users/DELETE_AAD_USER'
export const DELETE_AAD_USER_SUCCESS = 'app/Users/DELETE_AAD_USER_SUCCESS'
export const DELETE_AAD_USER_ERROR = 'app/Users/DELETE_AAD_USER_ERROR'

export const INVITE_USER = 'app/Users/INVITE_USER'
export const INVITE_USER_SUCCESS = 'app/Users/INVITE_USER_SUCCESS'
export const INVITE_USER_ERROR = 'app/Users/INVITE_USER_ERROR'

export const UNLINK_AAD_USER = 'app/Users/UNLINK_AAD_USER'
export const UNLINK_AAD_USER_SUCCESS = 'app/Users/UNLINK_AAD_USER_SUCCESS'
export const UNLINK_AAD_USER_ERROR = 'app/Users/UNLINK_AAD_USER_ERROR'
export const CLOSE_DEFAULT_ROLES_MODAL = 'app/Users/CLOSE_DEFAULT_ROLES_MODAL'

export const SET_USER_ROLES = 'app/Users/SET_USER_ROLES'
export const SET_USER_ROLES_SUCCESS = 'app/Users/SET_USER_ROLES_SUCCESS'
export const SET_USER_ROLES_ERROR = 'app/Users/SET_USER_ROLES_ERROR'

export const TOGGLE_SHOW_CREATE_USER_FORM =
  'app/Users/TOGGLE_SHOW_CREATE_USER_FORM'

export const FILTER_USERS = 'app/Users/FILTER_USERS'
export const CLEAR_FILTER = 'app/Users/CLEAR_FILTER'

export const COMPANY_USER = 'CompanyUser'
export const COMPANY_SUPER_USER = 'CompanySuperUser'
export const CUSTOMER_USER = 'CustomerUser'
export const CUSTOMER_SUPER_USER = 'CustomerSuperUser'
export const REPORT_READER = 'ReportReader'
export const GET_USERS_BY_COMPANY = 'app/Users/GET_USERS_BY_COMPANY'
export const GET_USERS_BY_COMPANY_SUCCESS =
  'app/Users/GET_USERS_BY_COMPANY_SUCCESS'
export const GET_USERS_BY_COMPANY_ERROR = 'app/Users/GET_USERS_BY_COMPANY_ERROR'
